/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { valueOf as e, exists as t } from "./get.js";
import { getProperties as r } from "./utils.js";
import { originSpecificReadPropertyDefinition as o, originSpecificPropertyDefinition as n } from "./extensions/serializableProperty.js";
function s(e, r, o) {
  if (!e?.read || !1 === e.read.enabled || !e.read.source) return !1;
  const n = e.read.source;
  if ("string" == typeof n) {
    if (n === r) return !0;
    if (n.includes(".") && 0 === n.indexOf(r) && t(n, o)) return !0;
  } else for (const s of n) {
    if (s === r) return !0;
    if (s.includes(".") && 0 === s.indexOf(r) && t(s, o)) return !0;
  }
  return !1;
}
function i(e) {
  return e && (!e.read || !1 !== e.read.enabled && !e.read.source);
}
function f(e, t, r, n, f) {
  let a = o(t[r], f);
  i(a) && (e[r] = !0);
  for (const i of Object.getOwnPropertyNames(t)) a = o(t[i], f), s(a, r, n) && (e[i] = !0);
}
function a(e, t, r, o) {
  const s = r.metadata,
    i = n(s[t], o),
    f = i?.default;
  if (void 0 === f) return;
  const a = "function" == typeof f ? f.call(e, t, o) : f;
  void 0 !== a && r.set(t, a);
}
const c = {
  origin: "service"
};
function u(t, n, s = c) {
  if (!n || "object" != typeof n) return;
  const i = r(t),
    u = i.metadata,
    d = {};
  for (const e of Object.getOwnPropertyNames(n)) f(d, u, e, n, s);
  i.setDefaultOrigin(s.origin);
  for (const r of Object.getOwnPropertyNames(d)) {
    const f = o(u[r], s).read,
      a = f?.source;
    let c;
    c = a && "string" == typeof a ? e(n, a) : n[r], f?.reader && (c = f.reader.call(t, c, n, s)), void 0 !== c && i.set(r, c);
  }
  if (!s || !s.ignoreDefaults) {
    i.setDefaultOrigin("defaults");
    for (const e of Object.getOwnPropertyNames(u)) d[e] || a(t, e, i, s);
  }
  i.setDefaultOrigin("user");
}
function d(e, t, r, o = c) {
  const n = {
    ...o,
    messages: []
  };
  r(n), n.messages?.forEach(t => {
    "warning" !== t.type || e.loaded ? o?.messages && o.messages.push(t) : e.loadWarnings.push(t);
  });
}
export { u as read, d as readLoadable };