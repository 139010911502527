/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { clone as i } from "../lang.js";
import { OriginId as e } from "./PropertyOrigin.js";
class s {
  constructor() {
    this._values = new Map(), this.multipleOriginsSupported = !1;
  }
  clone(e) {
    const t = new s();
    return this._values.forEach((s, r) => {
      e && e.has(r) || t.set(r, i(s.value), s.origin);
    }), t;
  }
  get(i, e) {
    e = this._normalizeOrigin(e);
    const s = this._values.get(i);
    return null == e || s?.origin === e ? s?.value : void 0;
  }
  originOf(i) {
    return this._values.get(i)?.origin ?? e.USER;
  }
  keys(i) {
    i = this._normalizeOrigin(i);
    const e = [...this._values.keys()];
    return null == i ? e : e.filter(e => this._values.get(e)?.origin === i);
  }
  set(i, s, r) {
    if ((r = this._normalizeOrigin(r)) === e.DEFAULTS) {
      const e = this._values.get(i);
      if (null != e?.origin && e.origin > r) return;
    }
    this._values.set(i, new t(s, r));
  }
  delete(i, e) {
    null != (e = this._normalizeOrigin(e)) && this._values.get(i)?.origin !== e || this._values.delete(i);
  }
  has(i, e) {
    return null != (e = this._normalizeOrigin(e)) ? this._values.get(i)?.origin === e : this._values.has(i);
  }
  forEach(i) {
    this._values.forEach(({
      value: e
    }, s) => i(e, s));
  }
  _normalizeOrigin(i) {
    if (null != i) return i === e.DEFAULTS ? i : e.USER;
  }
}
class t {
  constructor(i, e) {
    this.value = i, this.origin = e;
  }
}
export { s as DefaultsStore };